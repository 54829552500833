import React from 'react'
import * as styles from './YoutubeVideo.module.scss'

const youtubeVideoIdRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/

interface YoutubeVideoProps {
  videoIdOrLink: string;
  fullWidth?: boolean;
}

const YoutubeVideo = ({ videoIdOrLink, fullWidth }: YoutubeVideoProps) => {
  const videoId = videoIdOrLink.match(youtubeVideoIdRegex)?.[1]

  return (
    <div className={[styles.youtubeVideoWrapper, fullWidth && styles.fullWidth].join(' ')}>
      <iframe
        width='560'
        height='315'
        src={'https://www.youtube-nocookie.com/embed/' + videoId}
        frameBorder={0}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        loading='lazy'
      />
    </div>
  )
}

export default YoutubeVideo
