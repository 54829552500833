import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import * as styles from './ContentPage.module.scss'
import YoutubeVideo from './YoutubeVideo'

export interface ContentPageProps {
  sections: {
    title: string;
    image?: IGatsbyImageData;
    video?: string;
    content: React.ReactNode;
  }[];
}

const ContentPage = ({ sections }: ContentPageProps) => {
  const sectionsWithSlug = sections.map(section => ({
    ...section,
    slug: section.title.replace(/[^a-zA-Z0-9-_]/g, '')
  }))

  return (<>
    <nav className={styles.secondaryNav}>
      {sectionsWithSlug.map(section => (
        <a key={section.slug} href={`#${section.slug}`}>{section.title}</a>
      ))}
    </nav>
    {sectionsWithSlug.map(section => {
      const sectionImage = section.image && getImage(section.image)

      return (
        <section key={section.slug} className={styles.section}>
          <span id={section.slug} className={styles.anchor}/>
          <h2>{section.title}</h2>
          {sectionImage && <GatsbyImage
            image={sectionImage}
            alt={section.title}
            className={styles.sectionImage}
          />}
          {section.video && <YoutubeVideo videoIdOrLink={section.video} />}
          {section.content}
        </section>
      )
    })}
  </>)
}

export default ContentPage
