import React, { useMemo } from 'react'
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import ContentPage, { ContentPageProps } from '../components/ContentPage'
import * as styles from './Unternehmen.module.scss'
import Photoswipe, { openPhotoswipe } from '../components/Photoswipe'
import YoutubeVideo from '../components/YoutubeVideo'
import { useIntl } from "gatsby-plugin-intl"

type UnternehmenQueryProps = PageQuery<{
  hero: HeroImageData<IGatsbyImageData>;
  title: string;
  body: MarkdownField;
  media: {
    title: string;
    text?: string;
  }
  gallery: {
    title: string;
    text?: string;
    cases: {
      title: string;
      images: {
        subtitle: string;
        image: IGatsbyImageData & {
          childImageSharp: {
            resize: {
              src: string;
              width: number;
              height: number;
            }
          }
        };
      }[];
    }[];
  }
  sections: {
    title: string;
    image?: IGatsbyImageData;
    video?: string;
    text: MarkdownField;
  }[];
}> & {
  leistungenMarkdown: {
    frontmatter: Record<string, {
      sections: {
        video?: string;
      }[];
    }>;
  }
}

const UnternehmenPage = ({ data }: PageProps<UnternehmenQueryProps>) => {
  const { locale } = useIntl()
  const { markdownRemark, leistungenMarkdown } = data
  const frontmatter = markdownRemark.frontmatter[locale]!
  const leistungenFrontmatter = leistungenMarkdown.frontmatter[locale]!

  const openGallery = (thumbEl: HTMLElement, caseData: typeof frontmatter.gallery.cases[number]) => {
    openPhotoswipe(caseData.images.map(imageData => {
      const { src, width, height } = imageData.image.childImageSharp.resize
      return {
          src,
          w: width,
          h: height,
          title: imageData.subtitle
        }
    }), thumbEl)
  }

  const sectionsWithGallery = useMemo<ContentPageProps['sections']>(() => {
    const transformedSections = frontmatter.sections.map(section => ({
      ...section,
      content: <div dangerouslySetInnerHTML={{__html: section.text.html}} />
    }))

    const siteVideos = [...frontmatter.sections, ...leistungenFrontmatter.sections].reduce<string[]>((result, nextSection) => {
      if (nextSection.video)
        result.push(nextSection.video)
      return result
    }, [])

    const mediaSection = {
      title: frontmatter.media.title,
      content: (<>
        {frontmatter.media.text && <p>{frontmatter.media.text}</p>}
        <div className={styles.media}>
          {siteVideos.map(videoId => {
            return <div key={videoId} className={styles.video}>
              <YoutubeVideo videoIdOrLink={videoId} />
            </div>
          })}
          {new Array(2).fill(0).map((_, i) => <div key={i} className={[styles.video, styles.filler].join(' ')} />)}
        </div>
      </>)
    }

    const gallerySection = {
      title: frontmatter.gallery.title,
      content: (<>
        {frontmatter.gallery.text && <p>{frontmatter.gallery.text}</p>}
        <div className={styles.gallery}>
          {frontmatter.gallery.cases.map((caseData, index) => {
            const caseImage = caseData.images[0]?.image && getImage(caseData.images[0].image)
            return (
              <div key={index} className={styles.caseImg} onClick={e => openGallery(e.currentTarget, caseData)}>
                {caseImage && <GatsbyImage
                  image={caseImage}
                  alt={caseData.title}
                />}
                <div className={styles.title}>{caseData.title}</div>
              </div>
            )
          })}
          {new Array(3).fill(0).map((_, i) => <div key={i} className={[styles.caseImg, styles.filler].join(' ')} />)}
          <Photoswipe />
        </div>
      </>),
    }

    return [...transformedSections, mediaSection, gallerySection]
  }, [frontmatter, leistungenFrontmatter])

  return (
    <Layout
      seo={{title: frontmatter.title, description: frontmatter.body.html}}
      hero={frontmatter.hero}
      introCard={{
        title: frontmatter.title,
        body: frontmatter.body.html
      }}
    >
      <ContentPage sections={sectionsWithGallery} />
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment UnternehmenFragment on FrontmatterLocalized {
    hero {
      image {
        desktopHero: childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
        mobileHero: childImageSharp {
          gatsbyImageData(
            width: 760
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    title
    body {
      html
    }
    media {
      title
      text
    }
    gallery {
      title
      text
      cases {
        title
        images {
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(
                width: 250
                aspectRatio: 1.5
                placeholder: BLURRED
              )
              resize(
                width: 1920
                quality: 100
              ) {
                src
                width
                height
              }
            }
          }
        }
      }
    }
    sections {
      title
      image {
        childImageSharp {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
          )
        }
      }
      video
      text {
        html
      }
    }
  }

  fragment UnternehmenLeistungenFragment on FrontmatterLocalized {
    sections {
      video
    }
  }

  query UnternehmenPage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/unternehmen.md/"}) {
      frontmatter {
        de {
          ...UnternehmenFragment
        }
        en {
          ...UnternehmenFragment
        }
      }
    }
    leistungenMarkdown: markdownRemark(fileAbsolutePath: {regex: "/pages\/leistungen.md/"}) {
      frontmatter {
        de {
          ...UnternehmenLeistungenFragment
        }
        en {
          ...UnternehmenLeistungenFragment
        }
      }
    }
  }
`

export default UnternehmenPage
