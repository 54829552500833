import React from 'react'
import { Helmet } from 'react-helmet'

declare var PhotoSwipe: any
declare var PhotoSwipeUI_Default: any

export const openPhotoswipe = (items: {
  src: string;
  w: number;
  h: number;
  title?: string;
}[], thumbEl?: HTMLElement) => {
  const pswpElement = document.querySelectorAll('.pswp')[0]

  const options = {
    getThumbBoundsFn: !thumbEl ? undefined : () => {
      const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
      const rect = thumbEl.getBoundingClientRect()

      return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width,
        h: rect.height
      }
    },
    showHideOpacity: true,
    history: false,
    barsSize: {top:0,bottom:0},
    fullscreenEl: false,
    shareEl: false,
    bgOpacity: 0.85,
    tapToToggleControls: false
  }

  const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options)
  gallery.init()
}

const Photoswipe = () => {
  return (<>
    <Helmet>
      <link rel='stylesheet' href='https://www.unpkg.com/photoswipe@4.1.3/dist/photoswipe.css' />
      <link rel='stylesheet' href='https://www.unpkg.com/photoswipe@4.1.3/dist/default-skin/default-skin.css' />
      <script src='https://www.unpkg.com/photoswipe@4.1.3/dist/photoswipe.min.js'></script>
      <script src='https://www.unpkg.com/photoswipe@4.1.3/dist/photoswipe-ui-default.min.js'></script>
    </Helmet>
    <div className='pswp' tabIndex={-1} role='dialog' aria-hidden='true'>
      <div className='pswp__bg'></div>
      <div className='pswp__scroll-wrap'>
        <div className='pswp__container'>
          <div className='pswp__item'></div>
          <div className='pswp__item'></div>
          <div className='pswp__item'></div>
        </div>
        <div className='pswp__ui pswp__ui--hidden'>
          <div className='pswp__top-bar'>
            <div className='pswp__counter'></div>
            <button className='pswp__button pswp__button--close' title='Close (Esc)'></button>
            <button className='pswp__button pswp__button--share' title='Share'></button>
            <button className='pswp__button pswp__button--fs' title='Toggle fullscreen'></button>
            <button className='pswp__button pswp__button--zoom' title='Zoom in/out'></button>
            <div className='pswp__preloader'>
              <div className='pswp__preloader__icn'>
                <div className='pswp__preloader__cut'>
                  <div className='pswp__preloader__donut'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
            <div className='pswp__share-tooltip'></div>
          </div>
          <button className='pswp__button pswp__button--arrow--left' title='Previous (arrow left)'>
          </button>
          <button className='pswp__button pswp__button--arrow--right' title='Next (arrow right)'>
          </button>
          <div className='pswp__caption'>
            <div className='pswp__caption__center'></div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default Photoswipe
